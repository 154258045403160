<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';

export default {
   name: "errorMsg",
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   methods: {
      errorMsg(error) {
         const erro = error.response.data.validations.errors;
         if (erro.sql || !erro || erro.length === 0) {
            this.toast.error(error.response.data.validations.message, {
               timeout: false,
            });
         } else {
            for (let column in erro) {
               for (let msg in erro[column]) {
                  this.toast.error(erro[column][msg], {timeout: false});
               }
            }
         }
      },
   }
}
</script>

<style scoped>

</style>